import React from "react"
import { graphql } from "gatsby"

import Layout from "../components/layout"
import SEO from "../components/seo"
import InnerLayout from "../components/innerLayout"

const PortFolio = ({ data, location }) => {
  const project = data.markdownRemark
  const file = data.file.childImageSharp.fluid.src

  return (
    <Layout>
      <SEO
        title={project.frontmatter.title}
        description={project.frontmatter.description || project.excerpt}
      />
      <InnerLayout location={location}>
        <section id="portfolio-details" className="portfolio-details">
          <div className="container">
            <div className="row">
              <div className="col-lg-8">
                <h2 className="portfolio-title">{project.frontmatter.title}</h2>
                <div className=" portfolio-details-carousel">
                  <img
                    src={file}
                    className="img-fluid"
                    alt={project.frontmatter.title}
                  />
                </div>
              </div>

              <div className="col-lg-4 portfolio-info">
                <h3>Informations</h3>
                <ul>
                  <li>
                    <strong>Categorie</strong>: {project.frontmatter.category}
                  </li>
                  <li>
                    <strong>Client</strong>: {project.frontmatter.company}
                  </li>
                  <li>
                    <strong>Date</strong>: {project.frontmatter.date}
                  </li>
                  <li>
                    <strong>Démo</strong>: Cliquer sur le{" "}
                    <a href={project.frontmatter.url}>lien</a>
                  </li>
                </ul>

                <p dangerouslySetInnerHTML={{ __html: project.html }}></p>
              </div>
            </div>
          </div>
        </section>
      </InnerLayout>
    </Layout>
  )
}

export const pageQuery = graphql`
  query($path: String!, $image: String!) {
    markdownRemark(frontmatter: { path: { eq: $path } }) {
      html
      excerpt(pruneLength: 160)
      frontmatter {
        date(formatString: "DD MMMM, YYYY")
        path
        title
        category
        company
        url
        image
      }
    }
    file(relativePath: { eq: $image }) {
      relativePath
      childImageSharp {
        fluid {
          src
        }
      }
    }
  }
`

export default PortFolio
